import axios from "axios";

// 登入
const signin = async (data) => {
  return await axios.post("/a_api/account/login", data);
};

// OTP
const otp = async (obj) => {
  return await axios.post('a_api/account/sendEmailCode', obj);
};

// 用戶信息
const getUserInfo = async (data) => {
  return await axios.get("/a_api/account/profile", data);
};

// 用戶列表
const getUserList = async (data) => {
  return await axios.get("/a_api/account/users", { params: data });
};


// 用戶儲值
const addUserBalance = async (id, data) => {
  return await axios.post(`/a_api/dealer/${id}/addUserBalance`, data);
};

// 用戶儲值历史
const balanceHistory = async (id, userId) => {
  return await axios.get(`/a_api/dealer/${id}/user/${userId}/userBalance`);
};

// 設置用戶會員等級
const setUserLevel = async (id, data) => {
  return await axios.post(`/a_api/dealer/${id}/setUserMember`, data);
};

//重設密碼
const resetPassword = async (obj) => {
  return await axios.post('a_api/account/forgetPassword', obj);
};

// 修改密碼
const updatePassword = async (data) => {
  return await axios.post("/a_api/dealer/modifyPassword", data);
};

const userService = {
  signin,
  getUserInfo,
  getUserList,
  addUserBalance,
  setUserLevel,
  balanceHistory,
  updatePassword,
  resetPassword,
  otp,
};

export default userService;
