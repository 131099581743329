import axios from "axios";

// 公告列表
const getNoticeList = async (data) => {
  return await axios.get("/a_api/system/notice", { params: data });
};

// 創建公告
const addNotice = async (val) => {
  return await axios.post(`/a_api/system/notice`, val);
};

// 公告更新
const updateNotice = async (id, val) => {
  return await axios.put(`/a_api/system/notice/${id}`, val);
};

const deleteNotice = async (id) => {
  return await axios.delete(`/a_api/system/notice/${id}`);
};

const getNotice = async () => {
  return await axios.get(`/a_api/system/app-info`);
};

const noticeService = {
  getNoticeList,
  addNotice,
  updateNotice,
  deleteNotice,
  getNotice,
};

export default noticeService;
